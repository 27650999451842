<template>
    <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            @keyup.enter.native="dataFormSubmit()"
            label-width="120px"
            label-position="top"
            :disabled="type === 'view'"
        >
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="设备" prop="devNum">
                        <el-select v-model="dataForm.devNum" placeholder="设备" @change="devNumChange">
                            <el-option
                                v-for="item in deviceList"
                                :label="item.name"
                                :key="item.key"
                                :value="item.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="电话" prop="phone">
                        <el-input v-model="dataForm.phone" placeholder="发短信到的电话，多个电话使用,隔开"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="监听时间间隔" prop="monitor">
                        <el-input placeholder="请输入时间" v-model="dataForm.monitor">
                            <el-select
                                v-model="dataForm.timeUnit"
                                slot="prepend"
                                placeholder="请选择"
                                clearable
                                style="width: 120px; background-color: #161d30; border: 1px solid white"
                            >
                                <el-option
                                    v-for="item in timeBasis"
                                    :key="item.key"
                                    :value="item.value"
                                    :label="item.label"
                                ></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="配置类型" prop="type">
                        <el-select v-model="dataForm.type" placeholder="0.通用 1.自用" @change="typeChange">
                            <el-option
                                v-for="item in deviceType"
                                :value="item.key"
                                :key="item.key"
                                :label="item.label"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="使用状态" prop="isopen">
                        <el-select v-model="dataForm.isopen" :placeholder="tip ? tip : '0.不启用 1.启用'">
                            <el-option v-for="item in useSate" :value="item.key" :key="item.key" :label="item.label" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {cloneDeep} from 'lodash';
const deviceType = [
    {
        key: 0,
        label: '通用',
    },
    {
        key: 1,
        label: '自用',
    },
];
const useSate = [
    {
        key: 1,
        label: '启用',
    },
    {
        key: 0,
        label: '未启用',
    },
];
const timeBasis = [
    {
        key: 0,
        label: '秒',
        value: 's',
    },
    {
        key: 1,
        label: '分',
        value: 'm',
    },
    {
        key: 2,
        label: '时',
        value: 'h',
    },
];
export default {
    name: 'sdgljbnwekjibgnj',
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('不能为空'));
            } else {
                if (value !== '' || value !== undefined) {
                    let reg = new RegExp('^\\d*$');
                    if (!reg.test(value)) {
                        callback(new Error('输入数字'));
                    }
                }
                callback();
            }
        };
        return {
            visible: false,
            deviceType,
            useSate,
            deviceList: [],
            timeBasis,
            type: '',
            tip: '',
            dataForm: {
                id: 0,
                devNum: '',
                phone: '',
                monitor: '',
                type: '',
                timeUnit: '',
                isopen: 0,
                flag: '',
                devType: '',
                devName: '',
            },
            dataRule: {
                devNum: [{required: true, message: '不能为空', trigger: 'blur'}],
                monitor: [{required: true, validator: validatePass, trigger: 'blur'}],
                phone: [{required: true, message: '不能为空', trigger: 'blur'}],
                type: [{required: true, message: '不能为空', trigger: 'blur'}],
                timeUnit: [{required: true, message: '不能为空', trigger: 'blur'}],
            },
        };
    },
    created() {
        this.getDiviceList();
    },
    methods: {
        init(item) {
            this.dataForm.id = item.id || 0;
            this.visible = true;
            this.type = item.type;
            this.$nextTick(() => {
                this.$refs['dataForm'].resetFields();
                if (this.dataForm.id) {
                    this.$client.gainById(this.dataForm.id).then((data) => {
                        this.dataForm = data.data;
                        let flag = this.dataForm['monitor'];
                        this.dataForm.timeUnit = flag.substring(flag.length - 1);
                        this.dataForm.monitor = flag.match(/^\d+/g)[0];
                        this.dataForm.isopen = this.dataForm.isopen ? 1 : 0;
                        this.dataForm.type = this.dataForm.type ? 1 : 0;
                    });
                }
            });
        },
        async getDiviceList() {
            let res = await this.$client.gainListDevice();
            this.deviceList = res.data;
        },
        typeChange(type) {
            if (String(type) === '0') {
                let tip = '通用已有，不启用可存，否失败。或删除、或更新原有。';
                this.$client.gainListGeneral().then((res) => {
                    if (res.data && res.data.isopen) {
                        this.$message({
                            message: '通用已有!',
                            type: 'warning',
                            duration: 1500,
                        });
                        this.tip = '请选择不启用';
                    }
                });
            }
        },
        devNumChange(sel) {
            let device = this.deviceList.filter((i) => i.key === sel)?.[0];
            this.dataForm.devType = device.type;
            this.dataForm.devName = device.name;
        },

        // 表单提交
        dataFormSubmit() {
            let data = cloneDeep(this.dataForm);
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    let funcs;
                    if (!data.id) {
                        funcs = this.$client.saveDeviceMsgConf;
                        delete data['id'];
                    } else {
                        funcs = this.$client.updDeviceMsgConf;
                    }
                    data['monitor'] += data.timeUnit;
                    funcs(data).then((data) => {
                        if (data.code === 0) {
                            this.$message({
                                message: data.msg ? data.msg : '操作成功',
                                type: 'success',
                                duration: 1500,
                            });
                            this.visible = false;
                            this.$emit('refreshDataList');
                        }
                    });
                }
            });
        },
    },
};
</script>
