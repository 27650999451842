<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions box-btn">
            <el-button
                class="newBut"
                size="mini"
                style="margin: 1% 0 1% 1.3%; border-radius: 4px"
                type="primary"
                icon="el-icon-plus"
                @click="addOrUpdateHandle(null, 'ins')"
                >新增</el-button
            >
            <!-- <el-button
                type="danger"
                style="margin: 1% 0 1% 1.3%"
                @click="deleteHandle()"
                :disabled="dataListSelections.length <= 0"
                >批量删除</el-button
            > -->
        </div>
        <div class="ui-height-100" style="margin-left: 20px; heigth: 90%; margin-right: 20px; margin-top: -20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.gainListConfPage"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectionChangeHandle"
            >
                <template slot="append">
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="查看" placement="top">
                                <el-button
                                    size="mini"
                                    icon="el-icon-view"
                                    style="background-color: transparent; width: 10%; padding: 0"
                                    @click="addOrUpdateHandle(scope.row.id, 'view')"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <el-button
                                    icon="el-icon-edit"
                                    size="mini"
                                    style="background-color: transparent; width: 10%; padding: 0"
                                    @click="addOrUpdateHandle(scope.row.id, 'edit')"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <el-button
                                    icon="el-icon-delete"
                                    size="mini"
                                    style="background-color: transparent; width: 10%; padding: 0"
                                    @click="deleteHandle(scope.row.id)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="暂停定时" placement="top">
                                <el-button
                                    icon="el-icon-close"
                                    size="mini"
                                    @click="pause(scope.row)"
                                    style="background-color: transparent; width: 10%; padding: 0"
                                ></el-button>
                            </el-tooltip>
                            <!-- <el-tooltip class="item" effect="dark" content="重启定时" placement="top">
                                <el-button icon="el-icon-refresh" size="mini" @click="restart(scope.row)" style="background-color:transparent; width:10%;"></el-button>
                            </el-tooltip>    -->
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="refreshDataList"></add-or-update>
    </div>
</template>

<script>
import AddOrUpdate from './deviceMsgConf-add-or-update';
export default {
    name: 'DeviceMsgConf',
    data() {
        return {
            dataForm: {
                key: '',
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
        };
    },
    computed: {
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'devName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入设备名称或编号',
                    },
                    {
                        prop: 'phone',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入电话',
                    },
                ],
            };
        },
        columns() {
            return [
                {prop: 'devName', label: '设备名称'},
                {prop: 'devNum', label: '设备编号'},
                {prop: 'phone', label: '电话'},
                {
                    prop: 'monitor',
                    label: '定时间隔(/次)',
                    render: (row) => {
                        const {monitor} = row;
                        return String(monitor).replace('m', '分').replace('s', '秒').replace('h', '小时');
                    },
                },
                {
                    prop: 'type',
                    label: '类型',
                    render: (row) => {
                        const {type} = row;
                        return !type ? '通用' : '自用';
                    },
                },
                {
                    prop: 'isOpen',
                    label: '是否启用',
                    render: (row) => {
                        const {isopen} = row;
                        return isopen ? '启用' : '未启用';
                    },
                },
            ];
        },
    },
    components: {
        AddOrUpdate,
    },

    methods: {
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(id, type) {
            this.addOrUpdateVisible = true;
            let item = {id: id, type: type};
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(item);
            });
        },
        /**
         * 刷新列表
         */
        refreshDataList() {
            this.$refs.searchTable.searchHandler();
        },
        pause(row) {
            this.$client.pauseSchedulerConf(row.devNum).then((res) => {
                if (res.code == 0) {
                    this.refreshDataList();
                }
            });
        },
        restart(row) {
            this.$client.restartSchedulerConf(row.devNum).then((res) => {
                if (res.code == 0) {
                    this.refreshDataList();
                }
            });
        },
        // 删除
        deleteHandle(id) {
            var ids = id
                ? [id]
                : this.dataListSelections.map((item) => {
                      return item.id;
                  });
            this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client.delDeviceMsgConfBatch(ids).then((data) => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                    });
                    this.refreshDataList();
                });
            });
        },
    },
};
</script>
